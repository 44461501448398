import React from 'react';
import Layout from '../components/Layout';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import { LegalWrapper, Heading2, Heading4 } from '../components/styled';

const Imprint = () => (
    <Layout>
        <Navbar limited />
        <LegalWrapper>
            <Heading2>Imprint</Heading2>

            <div>
                <Heading4>Liability and information according to § 5 TMG</Heading4>
                <p>TAKKT GmbH</p>
                <p>Susanne Flug &amp; Johannes Pecher</p>
                <p>hello@takkt.io</p>

                <Heading4>Represented by:</Heading4>
                <p>
                    Information provided according to Sec. 5 German Telemedia Act (TMG):
                    <p>TAKKT GmbH</p>
                    <p>Leopoldstraße 31</p>
                    <p>80802 München</p>
                </p>
                <p>Managing directors: Susanne Flug &amp; Johannes Pecher</p>

                <Heading4>Contact:</Heading4>
                <p>Telephone: +49 89 20084119</p>
                <p>Email: hello@takkt.io</p>

                <Heading4>Register entry (Handelsregister):</Heading4>
                <p>Registering court: Amtsgericht München</p>
                <p>Registration number: HR B 243791</p>

                <Heading4>VAT:</Heading4>
                <p>VAT Id number according to Sec. 27 a German Value Added Tax Act: DE320072148</p>

                <Heading4>Dispute resolution</Heading4>
                <p>
                    The European Commission provides a platform for online dispute resolution (OS):
                    <a href='https://ec.europa.eu/consumers/odr'>
                        https://ec.europa.eu/consumers/odr
                    </a>
                    . Please find our email in the disclaimer.
                </p>
                <p>
                    We do not take part in online dispute resolutions at consumer arbitration
                    boards.
                </p>

                <Heading4>Liability for Contents</Heading4>
                <p>
                    As service providers, we are liable for own contents of these websites according
                    to Sec. 7, paragraph 1 German Telemedia Act (TMG). However, according to Sec. 8
                    to 10 German Telemedia Act (TMG), service providers are not obligated to
                    permanently monitor submitted or stored information or to search for evidences
                    that indicate illegal activities.
                </p>
                <p>
                    Legal obligations to removing information or to blocking the use of information
                    remain unchallenged. In this case, liability is only possible at the time of
                    knowledge about a specific violation of law. Illegal contents will be removed
                    immediately at the time we get knowledge of them.
                </p>

                <Heading4>Liability for Links</Heading4>
                <p>
                    Our offer includes links to external third party websites. We have no influence
                    on the contents of those websites, therefore we cannot guarantee for those
                    contents. Providers or administrators of linked websites are always responsible
                    for their own contents.
                </p>
                <p>
                    The linked websites had been checked for possible violations of law at the time
                    of the establishment of the link. Illegal contents were not detected at the time
                    of the linking. A permanent monitoring of the contents of linked websites cannot
                    be imposed without reasonable indications that there has been a violation of
                    law. Illegal links will be removed immediately at the time we get knowledge of
                    them.
                </p>

                <Heading4>Copyright</Heading4>
                <p>
                    Contents and compilations published on these websites by the providers are
                    subject to German copyright laws. Reproduction, editing, distribution as well as
                    the use of any kind outside the scope of the copyright law require a written
                    permission of the author or originator. Downloads and copies of these websites
                    are permitted for private use only. The commercial use of our contents without
                    permission of the originator is prohibited.
                </p>
                <p>
                    Copyright laws of third parties are respected as long as the contents on these
                    websites do not originate from the provider. Contributions of third parties on
                    this site are indicated as such. However, if you notice any violations of
                    copyright law, please inform us. Such contents will be removed immediately.
                </p>
            </div>
        </LegalWrapper>
        <Footer relative />
    </Layout>
);

export default Imprint;
