import * as React from 'react';

import { ContentWrapper, StyledLink, Footer } from './styled';

interface Props {
    relative?: boolean;
}

const FooterComponent: React.FC<Props> = ({ relative }) => {
    return (
        <Footer relative={relative}>
            <ContentWrapper>
                <StyledLink href='/#hero'>
                    © {new Date().getFullYear()} Takkt. <span>Powered by TAKKT GmbH.</span>
                </StyledLink>

                <div>
                    <StyledLink href='/imprint'>Imprint</StyledLink>
                    <StyledLink href='/privacy'>Privacy Policy</StyledLink>
                </div>
            </ContentWrapper>
        </Footer>
    );
};

export default FooterComponent;
